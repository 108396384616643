<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapState } from "vuex";

export default {
  name: "AllAccountsSystemIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("accounts", {
      contents: state => state.accounts
    })
  },

  methods: {
    ...mapActions("serviceproviders", {
      fetchTrunksForServiceProvider: "fetchTrunksForServiceProvider"
    }),

    ...mapActions("passwordpolicies", {
      getPasswordPolicies: "getPasswordPolicies"
    }),

    initContent() {
      return {
        ac_name: "",
        logopath: "",
        logo_url: "",
        total_outbound_threads: 0,
        ob_disabled: false,
        message_balance: 0,
        password_policy_id: ""
      };
    },

    async refreshScopeTemplate() {
      this.forceShowNotScoped =
        this.selectedAccountId !== "all" ||
        this.selectedServiceProviderId !== "all";
      if (this.forceShowNotScoped) {
        this.forceScopeName = "Service Provider";
      }
    }
  },

  watch: {
    selectedServiceProviderAccountCombination: {
      immediate: true,
      async handler() {
        await this.refreshScopeTemplate();
      }
    }
  },

  mounted() {
    this.fetchTrunksForServiceProvider();
    this.getPasswordPolicies();
    this.contentType = "Account";
    this.primaryKey = "ac_id";
    this.scope = "sys";
  }
};
</script>

<style lang="scss" scoped></style>
