<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-loading="isLoading" v-if="formAction === 'select'">
      <div class="block">
        <img
          :src="contentFormInfo.logo_url || '/Five9Logo.jpg'"
          @error="handleAltImg"
          style="width: 125px; height: 125px; text-align: left; object-fit: contain "
        />
      </div>
      <div class="titles">{{ __("Account Name") }}</div>
      <div class="details">{{ accountNameLabel }}</div>
      <div class="titles">{{ __("Linked Domain") }}</div>
      <div class="details">{{ domainLabel || "-" }}</div>
      <div class="titles">{{ __("Account ID") }}</div>
      <div class="details">{{ contentFormInfo.ac_id }}</div>
      <div class="titles">{{ __("Account Note") }}</div>
      <div class="details">{{ contentFormInfo.note }}</div>
      <div class="titles">{{ __("Account Status") }}</div>
      <div class="details">
        {{ showStatus(contentFormInfo.is_enabled) }}
      </div>
      <div class="titles">{{ __("Account Display Date Format") }}</div>
      <div class="details">
        {{
          contentFormInfo.display_date_format === ""
            ? "yyyy/MM/dd"
            : contentFormInfo.display_date_format
        }}
      </div>
      <div class="titles">{{ __("Account TimeZone") }}</div>
      <div class="details">
        {{
          contentFormInfo.default_timezone === ""
            ? "UTC"
            : contentFormInfo.default_timezone
        }}
      </div>
      <div class="titles">{{ __("Account Outbound Threads") }}</div>
      <div class="details">{{ contentFormInfo.total_outbound_threads }}</div>

      <div class="titles">{{ __("Account Migration State") }}</div>
      <div class="details">
        {{ migrationStates[contentFormInfo.migration_state] }}
      </div>

      <!--      <div class="api-key-manage-title">-->
      <!--        <div class="titles">API Keys</div>-->
      <!--        <div class="pl-10" @click="addNewApiKey">-->
      <!--          <font-awesome-icon-->
      <!--            :icon="['fas', 'plus-circle']"-->
      <!--            class="fa-md cell-item-pointer"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
      <div
        v-for="apiKey in contentFormInfo.ac_api_keys.data"
        :key="apiKey.api_key"
        class="details"
      >
        <div class="api-key-item">
          <span class="api-key">{{ apiKey.api_key }}</span>
          <span><copier :value="apiKey.api_key"></copier></span>
        </div>
      </div>
      <div style="display: flex; padding-top: 20px">
        <el-dropdown @command="handleAction" class="allAcActionDropDown">
          <el-button type="primary">
            {{ __("Actions") }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(command, index) in commandsToShow"
              :key="index"
              :command="command.command"
              v-show="
                contentFormInfo.is_enabled === 1
                  ? true
                  : command.label === 'switch'
                  ? false
                  : true
              "
              :disabled="!canWrite"
            >
              <div
                style="display: flex; justify-content: flex-start; align-items: center"
              >
                <img
                  :src="getIconSVG(command.icon)"
                  alt
                  height="18px"
                  width="18px"
                  style="margin-right: 10px"
                />
                <span>{{ command.label }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      :show-close="false"
      top="6vh"
      destroy-on-close
      fullscreen
    >
      <div class="account-form-dialog">
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header :title="__('Account')" :contentId="id" />
          </el-col>
        </el-row>
        <el-scrollbar :native="false">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
            hide-required-asterisk
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Name')" prop="ac_name">
                  <el-input v-model="contentForm.ac_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Note')" prop="note">
                  <el-input v-model="contentForm.note"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Logo')" prop="logopath">
                  <logo-uploader
                    :folder="logoFolder"
                    :file-url="contentForm.logo_url"
                    :fit="logoFit"
                    @on-success="handleSuccess"
                    @on-error="handleError"
                  >
                    <div slot="tip" class="el-upload__tip">
                      {{ __("jpg/jpeg files") }}
                    </div>
                  </logo-uploader>
                </el-form-item></el-col
              >
            </el-row>
            <div v-if="formAction === 'edit'">
              <el-row type="flex">
                <el-col :span="12" :offset="6">
                  <el-form-item
                    :label="__('Outbound Threads')"
                    prop="ob_thread"
                  >
                    <el-slider
                      style="margin-left: 5px"
                      v-model="contentForm.total_outbound_threads"
                      show-input
                      :max="ob_max"
                      :show-tooltip="false"
                      :disabled="
                        formAction === 'add' || contentForm.ob_disabled
                      "
                    ></el-slider>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item label="" prop="display_date_format">
                  <display-date-format-dropdown
                    v-model="contentForm.display_date_format"
                    @display-date-format-changed="displayDateFormatChanged"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Timezone')" prop="default_timezone">
                  <timezone-dropdown
                    :default_timezone="contentForm.default_timezone"
                    @timezone-changed="handleTimezoneChange"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('QforMe Trunk')" prop="trunk_id">
                  <el-select
                    v-model="contentForm.trunk_id"
                    style="width: 100%"
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="(item, index) in trunks(selectedServiceProviderId)"
                      :key="index"
                      :label="item.label"
                      :value="item.value.trunk_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Password Policy')"
                  prop="password_policy_id"
                >
                  <el-select
                    v-model="contentForm.password_policy_id"
                    style="width: 100%"
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="(item, index) in policies"
                      :key="index"
                      :label="item.policy_name"
                      :value="item.password_policy_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Migration State')"
                  prop="migration_state"
                >
                  <el-select
                    v-model="contentForm.migration_state"
                    style="width: 100%"
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="(item, key) in migrationStates"
                      :key="key"
                      :label="migrationStates[key]"
                      :value="key"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item>
                  <!-- <el-button
                    type="primary"
                    :loading="isLoading"
                    @click="submitForm"
                    class="submitBtn"
                    :disabled="!this.canWrite"
                    >{{ id === -1 ? "Create" : "Update" }}
                  </el-button> -->
                  <el-button class="cancelBtn" @click="cancelForm"
                    >{{ __("Cancel") }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import LogoUploader from "@/components/uploaders/types/AvatarUploader";
import { mapState, mapActions, mapGetters } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { EventBus } from "@/EventBus";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import TimezoneDropdown from "../../../../components/TimezoneDropdown";
import Copier from "@/components/Copier";
import _ from "lodash";
import DisplayDateFormatDropdown from "@/components/DisplayDateFormatDropdown";
import * as AccountsUtil from "@/utils/accounts";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    Copier,
    PageHeader,
    LogoUploader,
    TimezoneDropdown,
    DisplayDateFormatDropdown
  },
  props: {
    account: {
      type: Object,
      required: false,
      default: () => ({
        ac_name: "",
        note: "",
        ac_version: "",
        logopath: "",
        default_timezone: "",
        migration_state: ""
      })
    },

    id: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  data() {
    return {
      rules: {
        ac_name: [
          { required: true, trigger: "blur", message: __("Name is required") }
        ]
      },
      //accountForm: { ...this.account },
      accountFormKey: 0,
      emailReceiverList:
        this.account["email_receiver_list"] == null ||
        this.account["email_receiver_list"] === ""
          ? []
          : this.account["email_receiver_list"].split(","),
      isSubmitting: false,
      logoFolder: "logo",
      logoFileUrl: "",
      logoFit: "contain",
      uploadedFilePath: "",
      ob_max: 1,
      commands: [
        {
          command: "Switch to account",
          rules: {
            is_enabled: 1
          },
          icon: "campaign_actions/icon_reset.svg",
          selectAction: false,
          label: __("switch")
        },
        {
          command: "createOrEdit",
          rules: {
            is_enabled: 1
          },
          icon: "campaign_actions/icon_pencil.svg",
          label: __("View"),
          selectAction: false
        },
        {
          command: "delete",
          rules: {
            is_enabled: 1
          },
          icon: "campaign_actions/icon_trash_new.svg",
          label: __("Archive"),
          selectAction: false
        },
        {
          command: "active",
          rules: {
            is_enabled: 0
          },
          icon: "accounts/icon_check_complete.svg",
          label: __("Active"),
          selectAction: false
        },
        {
          command: "force delete",
          rules: {
            is_enabled: 0
          },
          icon: "campaign_actions/icon_trash_new.svg",
          label: __("Force Delete"),
          selectAction: false
        },
        {
          command: "flush cache",
          rules: {
            is_enabled: 1
          },
          icon: "campaign_actions/icon_clear_cache.svg",
          selectAction: false,
          label: __("Clear cache")
        }
      ]
    };
  },

  computed: {
    showStatus() {
      return status => {
        return status === 1 ? "active" : "inactive";
      };
    },
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),
    ...mapState("accounts", {
      timezones: state => state.timezones,
      isLoading: state => state.isLoading,
      migrationStates: state => state.accountMigrationStates
    }),
    ...mapState("passwordpolicies", {
      policies: state => state.policies
    }),

    ...mapGetters("serviceproviders", {
      trunks: "trunks"
    }),
    commandsToShow() {
      return _.filter(this.commands, command => {
        if (_.isEmpty(command.rules)) {
          return true;
        }
        return _.every(command.rules, (rule, key) => {
          return this.contentForm[key] === rule;
        });
      });
    },

    /**
     * Get the account name label for the currently selected account
     */
    accountNameLabel: function() {
      return AccountsUtil.accountNameLabel(this.contentForm);
    },

    domainLabel: {
      get() {
        let domainMapData = this.contentForm.ac_domain_map.data;
        if (!domainMapData.domain_id) {
          return "";
        }
        return `${domainMapData.domain_name} (${domainMapData.domain_id})`;
      }
    }
  },

  watch: {
    emailReceiverListString() {
      return this.emailReceiverList.join(",");
    },
    formAction: {
      immediate: true,
      deep: true,
      handler: function(val) {
        if (val === "edit") {
          if (this.contentForm.ob_max > 0) {
            this.ob_max = this.contentForm.ob_max;
          } else {
            this.ob_max = 1;
            this.contentForm.ob_disabled = true;
          }
        }
      }
    }
  },
  methods: {
    ...mapActions("accounts", {
      createAccount: "createAccount",
      updateAccount: "updateAccount",
      deleteContentMethod: "deleteAccount",
      undoDeleteContent: "undoDeleteAccount",
      bulkDeleteAccounts: "bulkDeleteAccounts",
      forceDeleteContentMethod: "forceDeleteAccount",
      flushCache: "flushCache"
      // addNewApiKeyToAccount: "addNewApiKeyToAccount"
    }),

    ...mapActions("upload", {
      deleteLogo: "deleteLogo"
    }),

    ...mapActions("app", {
      changeUserSelectedAccountId: "changeUserSelectedAccountId",
      generateUserAccountSwitcherOptions: "generateUserAccountSwitcherOptions"
    }),

    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },

    handleAltImg(event) {
      event.target.src = "/Five9Logo.jpg";
    },

    handleSuccess(res) {
      console.log(res.url);
      this.contentForm.logopath = res.path;
      this.contentForm.logo_url = res.url;
      this.uploadedFilePath = res.path;
    },

    async handleTimezoneChange(timezone) {
      this.contentForm.default_timezone = timezone;
    },

    /**
     * handle display date format changed
     * @param displayDateFormat
     */
    displayDateFormatChanged(displayDateFormat) {
      this.contentForm.display_date_format = displayDateFormat;
    },

    handleError(err) {
      console.log(err);
    },

    async handleBulkDeleteAccounts() {
      await this.bulkDeleteAccounts({
        ac_ids: [this.contentFormInfo.ac_id]
      })
        .then(data => {
          if (data !== undefined) {
            this.$message({
              name: "success",
              message: data.message
            });
            EventBus.$emit("list-changed");
            this.$emit("handleCancel");
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.response.data.message
          });
        });
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createAccount : this.updateAccount;

          this.contentForm.sp_id = this.selectedServiceProviderId;
          this.contentForm.email_receiver_list = this.emailReceiverListString;
          process(this.contentForm)
            .then(data => {
              this.contentFormKey = this.contentFormKey + !this.id ? 0 : 1;
              // hack to refresh the user form to persist recent modifications
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("Account added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Account updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    cancelForm() {
      if (this.id === -1) {
        this.deleteLogo({ path: this.uploadedFilePath })
          .then(() => {
            this.handleCancel();
          })
          .catch(err => {
            console.log(err);
            this.handleCancel();
          });
      }
      this.ob_max = 1;
      this.contentForm.ob_disabled = true;
      this.handleCancel();
    },

    // addNewApiKey() {
    //   this.addNewApiKeyToAccount(this.contentForm)
    //     .then(({ data }) => {
    //       console.log(data.data);
    //       EventBus.$emit("list-changed", data.data);
    //       this.$message({
    //         message: "New API Key added successfully",
    //         type: "success"
    //       });
    //     })
    //     .catch(err => {
    //       this.$message({
    //         message: err.message || "Operation Failed",
    //         type: "error"
    //       });
    //     });
    // },
    handleSwitch() {
      this.changeUserSelectedAccountId(this.contentForm.ac_id);
      this.generateUserAccountSwitcherOptions({
        ac_id: this.contentForm.ac_id,
        sp_id: this.contentForm.sp_id
      });
    },
    handleAction(action) {
      if (action === "delete") {
        // eslint-disable-next-line
        this.$confirm(__('Please confirm that you want to proceed with deleting this account.'), __('Confirmation'), {
            confirmButtonText: __("Yes"),
            cancelButtonText: __("No"),
            type: "warning"
          }
        )
          .then(() => {
            if (this.contentFormInfo.is_enabled === 1) {
              this.handleDelete(this.contentForm);
            } else {
              this.handleBulkDeleteAccounts();
            }
          })
          .catch(() => {
            // Do nothing
          });
      } else if (action === "createOrEdit") {
        this.handleEdit();
      } else if (action === "active") {
        this.handleActive(this.contentForm);
      } else if (action === "force delete") {
        // eslint-disable-next-line
        this.$confirm(__('Please confirm that you want to proceed with deleting this account.'), __('Confirmation'), {
            confirmButtonText: __("Yes"),
            cancelButtonText: __("No"),
            type: "warning"
          }
        )
          .then(() => {
            this.forceDeleteContentMethod(this.contentForm);
          })
          .catch(() => {
            // Do nothing
          });
      } else if (action === "flush cache") {
        this.handleFlushCache(this.contentForm);
      } else if (action === "Switch to account") {
        this.handleSwitch();
      } else {
        this.handleSwitch();
      }
    },

    handleFlushCache(contentForm) {
      this.flushCache(contentForm)
        .then(() => {
          this.$message.success("Cache cleared");
        })
        .catch(err => {
          this.$message.error(err.message || "Failed to clear cache");
        });
    },

    handleActive(contentForm) {
      this.undoDeleteContent(contentForm).then(() => {
        EventBus.$emit("list-changed");
      });
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .account-form-dialog {
  max-height: 90vh !important;
}

.api-key-manage-title {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  .pl-10 {
    padding-left: 10px;
  }
}

.api-key-item {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .api-key {
    word-break: break-word;
    padding-right: 5px;
  }
}

.allAcActionDropDown {
  color: white;
  background: $content-theme-color;
  border-radius: 6px;
  margin-top: 20px;

  ::v-deep button {
    background: $content-theme-color;
    border-color: transparent;
    height: 44px;
    &:disabled {
      color: white;
      //background-color: mix(white, $content-theme-color, 50%);
      border-color: $content-theme-outline-color !important;
    }
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
    }
  }
}
</style>
