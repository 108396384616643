<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :navItems="navItems"
      @navItemChanged="handleNavItemChanged"
      :title="__('All Accounts')"
    />
    <div style="padding: 0 36px" v-loading="isLoading">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :search-fields="searchFieldsString"
          :adv-search-prefill="searchQueryString"
          :search-join="searchJoinMethod"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
          <el-button
            class="bulkMoveBtn"
            slot="advancedSearch"
            @click="showAdvancedSearch = true"
          >
            <img
              :src="getAdvancedSearchImage"
              class="slotImg"
              onload="SVGInject(this)"
            />
          </el-button>
          <template v-if="this.multiSelectHasItem">
            <el-col :span="1" slot="buttonGroup">
              <span>
                <el-button class="bulkMoveBtn" @click="showBulkDelete = true">
                  <img
                    :src="getDeleteImage"
                    class="slotImg"
                    onload="SVGInject(this)"
                  />
                </el-button>
              </span>
            </el-col>
            <el-col :span="4" slot="buttonGroup">
              <span
                style="margin: 10px 18px;align-items: initial;display: inline-block;"
              >
                {{ this.selectedAccountsCount }} account(s)
              </span>
            </el-col>
          </template>
        </PaginationToolbar>
      </el-form>
      <!-- Advanced Search Dialog -->
      <el-dialog
        title="Advanced Search"
        :visible.sync="showAdvancedSearch"
        width="30%"
        destroy-on-close
      >
        <div>
          <el-scrollbar :native="false">
            <el-form
              ref="advSearchForm"
              v-model="searchOptions"
              label-position="top"
              size="large"
              hide-required-asterisk
            >
              <el-row type="flex">
                <el-col :span="16">
                  <el-form-item
                    label="Service provider"
                    prop="searchOptions.sp_id"
                  >
                    <el-select
                      filterable
                      v-model="searchOptions.sp_id"
                      clearable
                      placeholder="Select"
                      default-first-option
                    >
                      <el-option
                        v-for="(item, index) in serviceProvidersIdAndName"
                        :key="index"
                        :label="item.sp_name"
                        :value="item.sp_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="4">
                  <el-form-item>
                    <el-button @click="handleAdvSearchClick" class="submitBtn"
                      >Search
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :span="4" style="margin-left: 10px">
                  <el-form-item>
                    <el-button @click="handleResetClick" class="resetBtn"
                      >Reset</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-scrollbar>
        </div>
      </el-dialog>
      <!-- Delete Dialog -->
      <el-dialog
        title="Delete the following account(s)?"
        :visible.sync="showBulkDelete"
        width="30%"
        destroy-on-close
      >
        <el-form>
          <div>
            <el-row type="flex">
              <el-col :span="20">
                <el-form-item>
                  <el-scrollbar class="dialog-data" :native="false">
                    <div
                      v-for="account in getSelectedAccounts()"
                      :key="account"
                      class="text item"
                    >
                      {{ account }}
                    </div>
                  </el-scrollbar>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="3">
                <el-form-item style="padding-top: 10px">
                  <el-button
                    type="primary"
                    @click="handleBulkDeleteAccounts()"
                    class="submitBtn"
                    v-loading="isLoading"
                  >
                    Delete
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-dialog>

      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="accountTable"
            class="list-table"
            :data="accounts"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            @selection-change="handleSelectionChange"
            v-show="canRead()"
          >
            <el-table-column
              v-if="showingInactiveAccounts"
              type="selection"
              width="55"
            >
            </el-table-column>
            <el-table-column label="ID" prop="ac_id" />
            <el-table-column label="Name" prop="ac_name">
              <template slot-scope="scope">
                <el-row
                  type="flex"
                  justify="flex-start"
                  style="align-items: center"
                >
                  <img
                    :src="scope.row.logo_url || '/Five9Logo.jpg'"
                    @error="handleAltImg"
                    class="rounded-menu-icon"
                  />
                  <span style="margin-left: 20px;">{{
                    accountNameLabel(scope.row)
                  }}</span>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column :label="__('Service Provider')" prop="sp_name">
              <template slot-scope="scope">
                <span>{{ scope.row.service_provider.data.sp_name }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="__('Note')" prop="note" />
            <el-table-column
              v-if="showingInactiveAccounts"
              :label="__('Days left')"
              prop="days_left_sys"
            />
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import PageHeader from "@/components/PageHeader";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";
import { EventBus } from "@/EventBus";
import * as AccountsUtil from "@/utils/accounts";

export default {
  name: "AllAccountsSystemList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  props: {
    msg: {
      required: false,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      sortByColumns: ["ac_name", "ac_id"],
      fetchedAccounts: false,
      search: "",
      filters: ["ac_name"],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      },
      showAdvancedSearch: false,
      searchFieldsString: "",
      searchOptions: {},
      searchQueryString: "",
      searchJoinMethod: "",
      multipleSelection: [],
      showingInactiveAccounts: false,
      showBulkDelete: false,
      navItems: [
        {
          label: "Active Accounts",
          displayText: __("Active Accounts"),
          active: true
        },
        {
          label: "Inactive Accounts",
          displayText: __("Inactive Accounts"),
          active: false
        }
      ]
    };
  },

  created() {
    this.initializeSearchOptions();
  },

  computed: {
    ...mapState("accounts", {
      accounts: state => state.accounts,
      isLoading: state => state.isLoading
    }),

    ...mapGetters("app", {
      serviceProvidersIdAndName: "serviceProvidersIdAndName"
    }),

    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),

    accountsToShow() {
      return this.accounts.filter(
        account =>
          !this.search || this.filterAccountOnSearchQuery(account, this.search)
      );
    },

    multiSelectHasItem() {
      return this.multipleSelection.length > 0;
    },

    selectedAccountsCount() {
      let self = this;
      return self.multipleSelection.length;
    },
    /**
     * get the advanced search image
     * @returns {*}
     */
    getAdvancedSearchImage() {
      return require("@/assets/icons/preview_table/query_builder_ds.svg");
    },

    getDeleteImage() {
      return require("@/assets/icons/preview_table/delete_ds_data.svg");
    }
  },

  mounted() {
    this.setAccountsFilter(1);
    if (this.msg) {
      this.$message({
        type: "success",
        message: this.msg
      });
    }
  },
  methods: {
    accountNameLabel: AccountsUtil.accountNameLabel,

    ...mapActions("accounts", {
      contentAPI: "getPaginatedAccountsForSystem",
      deleteContentMethod: "deleteAccount",
      undoDeleteContent: "undoDeleteAccount",
      setAccountsFilter: "setAccountsFilter",
      bulkDeleteAccounts: "bulkDeleteAccounts"
    }),

    async initializeContents() {
      this.fetchedAccounts = false;
      if (!this.msg && this.selectedServiceProviderId !== "all") {
        await this.contentAPI();
      }
      this.fetchedAccounts = true;
    },

    handleAltImg(event) {
      event.target.src = "/Five9Logo.jpg";
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      if (this.selectedAccountsCount <= 1) {
        this.handleSelect(null, row);
      }
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      if (row.is_enabled !== 0) {
        this.handleEdit(null, row);
      }
    },

    handleClear() {
      this.$refs.accountTable && this.$refs.accountTable.setCurrentRow();
      this.handleClearSelection();
    },

    clearAside() {
      this.handleClearAside();
    },
    /**
     * init the search options
     */
    initializeSearchOptions() {
      this.$set(this.searchOptions, "sp_id", "");
    },

    handleNavItemChanged(navItem) {
      this.navItems = _.map(this.navItems, navItem => {
        navItem.active = false;
        return navItem;
      });
      if (navItem.label === "Inactive Accounts") {
        this.showingInactiveAccounts = true;
      } else {
        this.showingInactiveAccounts = false;
      }
      _.find(this.navItems, { label: navItem.label }).active = true;
      this.setAccountsFilter(navItem.label === "Active Accounts" ? 1 : 0);
      EventBus.$emit("list-changed");
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    getSelectedAccounts() {
      return _.flatMap(_.map(this.multipleSelection, "ac_name"));
    },

    getSelectedAccountIds() {
      return _.flatMap(_.map(this.multipleSelection, "ac_id"));
    },

    async handleBulkDeleteAccounts() {
      await this.bulkDeleteAccounts({
        ac_ids: this.getSelectedAccountIds()
      })
        .then(data => {
          if (data !== undefined) {
            this.$message({
              name: "success",
              message: data.message
            });
            this.handleResetMultiSelectItems();
            EventBus.$emit("list-changed");
          }
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.response.data.message
          });
        });
    },

    handleResetMultiSelectItems() {
      this.showBulkDelete = false;
      this.multipleSelection = [];
    },
    /**
     * build search fields
     */
    buildSearchFields() {
      let equalConditionQualifiers = ["sp_id"];
      let searchFields = _.flatMap(this.searchOptions, (value, key) => {
        if (typeof value === "object") {
          let object_properties = _.keys(value);
          return _.map(object_properties, obj_key => {
            return `${key}~${obj_key}`;
          });
        }
        if (equalConditionQualifiers.includes(key)) {
          key = `${key}:=`;
        }
        return key;
      });
      this.searchFieldsString = searchFields.join(";");
      this.searchJoinMethod = "and";
    },

    /**
     * handle search button clicked
     * @returns {Promise<void>}
     */
    async handleAdvSearchClick() {
      let self = this;
      await this.buildSearchFields();
      let searchQueries = _.compact(
        _.flatMap(self.searchOptions, (value, key) => {
          if (typeof value === "object") {
            return _.flatMap(value, (obj_val, obj_key) => {
              if (obj_val !== "") {
                return `${key}~${obj_key}:${obj_val}`;
              }
            });
          } else if (value !== "") {
            return `${key}:${value}`;
          }
        })
      );
      this.searchQueryString = searchQueries.join(";");
      this.showAdvancedSearch = false;
      this.handleRefreshData();
    },

    /**
     * reset search
     */
    handleResetClick() {
      this.searchQueryString = "";
      this.searchFieldsString = "";
      this.searchJoinMethod = "";
      this.initializeSearchOptions();
      this.handleRefreshData();
    },

    /**
     * update the list
     */
    handleRefreshData() {
      this.contentAPI().then(data => {
        EventBus.$emit("list-changed", data.data);
      });
    }
  },
  watch: {
    selectedServiceProviderId: "initializeContents",
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null && val !== undefined) {
          this.$refs.accountTable &&
            this.$refs.accountTable.setCurrentRow(this.accounts[val]);
        } else if (val === -1) {
          this.$refs.accountTable && this.$refs.accountTable.setCurrentRow();
        }
      }
    },
    selectedAccountsCount: {
      immediate: true,
      handler: function(val) {
        if (val > 1) {
          this.handleClear();
          this.clearAside();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$--color-node-hover-default: var(--theme-hover-color) !default;
$--color-white: #ffffff !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/button.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/pagination-toolbar.scss";
.rounded-menu-icon {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  object-fit: cover;
  background: white;
}
.bulkMoveBtn {
  margin-left: 10px;
  height: 44px;
  width: 30px;
  border-color: #f5f5f8;
  color: #a0a8b5;
  &:hover {
    background-color: transparent;
    border-color: $content-theme-color;
    ::v-deep svg {
      fill: $content-theme-hover-color;
      path {
        fill: $content-theme-hover-color;
      }
    }
  }
  .slotImg {
    width: 24px;
    margin-left: -10px !important;
  }
  &.is-disabled:hover,
  &.is-disabled {
    color: #a0a8b5;
    border-color: #f5f5f8;
    ::v-deep svg {
      fill: #a0a8b5;
      path {
        fill: #a0a8b5;
      }
    }
  }
}
.bulkImg {
  width: 24px;
  margin-left: -10px;
}
.table-actions {
  font-weight: 600;
  font-size: larger;
  margin-left: -5px;
  margin-right: 5px;
}
.text {
  font-size: 14px;
}
.item {
  padding: 2px 0;
}

.dialog-data {
  height: calc(20vh - 10px);
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
<style lang="scss">
.advanceSearchInput {
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: var(--theme-color) !important;
  }
}
.advanceSearchTextArea {
  .el-textarea__inner:focus {
    border-color: var(--theme-color) !important;
  }
}
</style>
